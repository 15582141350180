import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Radio as AntRadio } from 'antd';

const RadioButton = ( { children, description, disabled, label, icon, ...restProps } ) => {

    const childrenToRender = (
        <Fragment>
            { icon ? (
                <span className='origami-radio-lock-icon'>
                    { icon }
                </span>
            ) : null }

            <span className='origami-radio-button-label'>
                { label ? label : children }
            </span>

            { description ? (
                <span className='origami-radio-button-description'>
                    { description }
                </span>
            ) : null }
        </Fragment>
    );

    return (
        <AntRadio.Button { ...restProps } >
            { childrenToRender }
        </AntRadio.Button>
    );
};

RadioButton.propTypes = {
    /** option description */
    description: PropTypes.string,
    /** disabled state */
    disabled: PropTypes.bool,
    /** option label */
    label: PropTypes.string,
    /** display the lock icon */
    showLockIcon: PropTypes.bool,
};
export default RadioButton;