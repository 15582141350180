import React, { useState, useRef, useEffect } from 'react';
import { Popover, Button, NackleItem } from '..';
import classNames from 'classnames';

/**
 * Renders a list of NackleItems.
 * @param {Object} props - The component props.
 * @param {Array} props.items - The array of items to render.
 * @param {string} props.size - The size of the items.
 * @param {Function} props.onClick - The click handler for the list.
 * @returns {JSX.Element} The rendered list of NackleItems.
 */
const NackleItemList = ( props ) => {
	const { items, size } = props;
	items.sort( ( a, b ) => { a.order > b.order ? 1 : -1; } );
	return (
		<span onClick={ props.onClick }>
			{ items.map( ( item, index ) => {
				return (
					<NackleItem
						key={ index }
						leftIcon={ item?.leftIcon }
						title={ item?.title }
						description={ item?.description }
						itemOnClick={ item?.onClick }
						size={ size || '' }
					></NackleItem>
				);
			} ) }
		</span>
	);
};

/**
 * A multi-button component with optional popover menus.
 * @param {Object} props - The component props.
 * @param {Object} props.left - Configuration for the left button.
 * @param {Object} props.right - Configuration for the right button.
 * @param {Object} props.popoverProps - Props for the Popover component.
 * @param {Function} props.onClick - Click handler for the component.
 * @param {string} props.menuSize - Size of the menu.
 * @param {Object} props.itemProps - Props for the items.
 * @param {Array} props.items - Array of items for the menu.
 * @param {string} props.size - Size of the buttons.
 * @param {string} props.className - Additional CSS class for the component.
 * @returns {JSX.Element} The rendered NackleMultiButton component.
 */
const NackleMultiButton = ( props ) => {
	const {
		left,
		right,
		popoverProps,
		onClick,
		menuSize,
		itemProps,
		items,
		size,
		className,
		...restProps
	} = props;
	const [ openPopover, setPopoverOpen ] = useState( false );
	const popoverRefLeft = useRef();
	const popoverRefRight = useRef();

	/**
	 * Toggles the popover open state.
	 */
	const togglePopover = () => {
		setPopoverOpen( !openPopover );
	};

	useEffect( () => {
		/**
		 * Handles clicks outside the popover to close it.
		 * @param {Event} event - The click event.
		 */
		const handleClickOutside = ( event ) => {
			if ( popoverRefLeft.current && !popoverRefLeft.current.contains( event.target ) ) {
				setTimeout( () => {
					setPopoverOpen( false );
				}, 100 );
			}
			if ( popoverRefRight.current && !popoverRefRight.current.contains( event.target ) ) {
				setTimeout( () => {
					setPopoverOpen( false );
				}, 100 );
			}
		};
		document.addEventListener( 'mousedown', handleClickOutside );
		return () => {
			document.removeEventListener( 'mousedown', handleClickOutside );
		};
	}, [ popoverRefLeft, popoverRefRight ] );

	const buttonClasses = classNames( {
		'left-button ant-btn-icon-only': left.icon ? true : false,
		'left-button': left.text ? true : false,
	} );

	if ( left.menu ) {
		return (
			<div className={ className ? `${ className } multi-button` : 'multi-button' } ref={ popoverRefLeft }>
				<Popover
					placement='bottomLeft'
					overlayClassName='popover-menu multi-menu'
					content={ <NackleItemList items={ items } size={ size } onClick={ togglePopover } /> }
					onClick={ togglePopover }
					open={ openPopover }
					{ ...popoverProps }
				>
					<Button
						{ ...restProps }
						className='left-button'
						icon={ left?.icon }
						size={ size }
						onClick={ togglePopover }
					></Button>
				</Popover>
				<Button
					{ ...restProps }
					className='right-button'
					onClick={ right.onClick }
					size={ size }
				>
					{ right.text ? right.text : right.icon }
				</Button>
			</div>
		);
	} else if ( right.menu ) {
		return (
			<div className={ className ? `${ className } multi-button` : 'multi-button' } ref={ popoverRefRight }>
				<Button
					{ ...restProps }
					className={ buttonClasses }
					onClick={ left.onClick }
					size={ size }
				>
					{ left.text ? left.text : left.icon }
				</Button>
				<Popover
					placement='bottomRight'
					overlayClassName='popover-menu multi-menu'
					content={ <NackleItemList items={ items } size={ size } /> }
					onClick={ togglePopover }
					open={ openPopover }
					{ ...popoverProps }
				>
					<Button
						{ ...restProps }
						className='right-button'
						icon={ right?.icon }
						onClick={ togglePopover }
						size={ size }
					></Button>
				</Popover>
			</div>
		);
	} else {
		return (
			<div className={ className ? `${ className } multi-button` : 'multi-button' }>
				<Button
					{ ...restProps }
					className='icon-left-button'
					onClick={ left.onClick }
					size={ size }
				>
					{ left.icon }
				</Button>
				<Button
					{ ...restProps }
					className='icon-right-button'
					onClick={ right.onClick }
					size={ size }
				>
					<span>{ left.icon ? left.icon : left.text }</span>
				</Button>
			</div>
		);
	}
};

export { NackleMultiButton };