import React, { forwardRef } from 'react';
import { Radio as AntRadio } from 'antd';
import Button from './button';
import Card from './card';
import Group from './group';
import CardGroup from './card-group';


const Radio = forwardRef( ( { ...props }, ref ) => {

    const statusClass = props.status ? `origami-radio-${ props.status }` : '';
    const sizeClass = props.size ? props.size : 'small';

    return (
        <AntRadio
            ref={ ref }
            className={ `${ statusClass } ${ sizeClass }` }
            { ...props }
        />
    );
} );

Radio.Group = Group;
Radio.Button = Button;
Radio.Card = Card;

export { CardGroup };
export { Card as NackleSelectCard };
export { Radio };