import React from 'react';
import { Dropdown as AntDrop } from 'antd';
import { CaretRightSmall, MoreDotsHorz } from '@nackle/origami-icons';
import classNames from 'classnames';
const getSize = ( size ) => {
    if ( !size ) {
        return '';
    }
    if ( size === 'large' ) {
        return 'ant-dropdown-large';
    }
    if ( size === 'small' ) {
        return 'ant-dropdown-small';
    }
    return '';
};
const MenuRender = ( { menu, dropdownClasses } ) => {
    return (
        <div className={ dropdownClasses }>
            {
                React.cloneElement( menu )
            }
        </div>
    );
};

const Dropdown = ( { children, ...props } ) => {
    const dropdownSize = getSize( props.size );
    const arrowClass = props.arrow ? '' : 'no-arrow';
    const dropdownClasses = classNames( {
        'ant-dropdown-content': true,
        [ dropdownSize ]: true,
        [ props.className ]: props.className ? true : false,
        [ arrowClass ]: true
    } );
    return (
        <AntDrop { ...props }
            menu={ { ...props.menu, expandIcon: <CaretRightSmall /> } }
            dropdownRender={ ( menu ) => MenuRender( { menu, dropdownClasses } ) }
        >{ children }</AntDrop>
    );
};

Dropdown.Button = ( { children, ...props } ) => {
    const { icon } = props;

    return (
        <AntDrop.Button { ...props } icon={ icon ? icon : <MoreDotsHorz /> }>{ children }</AntDrop.Button>
    );
};

export default Dropdown;