import React, { useState, useEffect, useRef } from 'react';
import { Radio as AntRadio } from 'antd';
import Card from './card';
import AnimateHeight from 'react-animate-height';

/**
 * @typedef {Object} Option
 * @property {string} value - The value of the option
 * @property {string} label - The label of the option
 * @property {string} [description] - The description of the option
 * @property {string} [icon] - The icon for the option
 * @property {boolean} [disabled] - Whether the option is disabled
 * @property {string} [disabledReason] - The reason for disabling the option
 * @property {boolean} [hasError] - Whether the option has an error
 * @property {boolean} [showButton] - Whether to show a button for the option
 * @property {Object} [buttonProps] - Props for the button
 */

/**
 * CardGroup component for rendering a group of radio cards
 * @param {Object} props - The component props
 * @param {Option[]} props.options - Array of options to render as cards
 * @param {string} props.value - The currently selected value
 * @param {Function} props.onChange - Callback function when selection changes
 * @param {string} [props.icon] - Icon for the cards
 * @param {boolean} [props.reversed] - Whether to reverse the layout
 * @param {boolean} [props.errorState] - Whether the group is in an error state
 * @param {boolean} [props.showCheckIcon] - Whether to show a check icon
 * @param {string} props.name - Name attribute for the radio group
 * @param {boolean} [props.allowCollapse] - Whether to allow collapsing of the group
 * @param {boolean} [props.startCollapsed] - Whether to start with the grouping collapsed
 * @param {string} [props.defaultValue] - Value of selected item when startCollapsed = true
 * @returns {React.Component} CardGroup component
 */
const CardGroup = ( {
    options,
    value,
    onChange,
    icon,
    reversed,
    errorState,
    showCheckIcon,
    name,
    allowCollapse,
    startCollapsed,
    defaultValue,
    ...restProps
} ) => {
    const [ isCollapsed, setCollapsed ] = useState( false );
    const [ childrenToRender, setChildrenToRender ] = useState( [] );
    const [ isCollapsedItem, setIsCollapsedItem ] = useState( false );
    const [ containerHeight, setContainerHeight ] = useState( 'auto' );
    const containerRef = useRef();
    const marginRef = useRef();

    /**
     * Calculates and sets the height of empty elements
     */
    const calculateHeight = () => {
        let empty1offset = 10;
        let empty2offset = 20;
        const baseHeight = 54;
        const baseEl = document.querySelector( `.${ name }` );

        const checkedElement = baseEl.querySelector( '.ant-radio-wrapper-checked' );
        const height = checkedElement ? checkedElement.offsetHeight : 0;
        if ( height > baseHeight ) {
            const additionalHeight = height - baseHeight;
            empty1offset = empty1offset + additionalHeight;
            empty2offset = empty2offset + additionalHeight;
        }
        const empty1 = baseEl.querySelector( '.empty-1' );
        const empty2 = baseEl.querySelector( '.empty-2' );
        if ( empty1 && empty2 ) {
            empty1.style.top = `${ empty1offset }px`;
            empty2.style.top = `${ empty2offset }px`;
        } else {
            setTimeout( () => {
                calculateHeight();
            }, 100 );
        }
    };

    /**
     * Handles the collapse/expand action of the group
     * @param {boolean} hasError - Whether the selected option has an error
     */
    const handleCollapse = ( hasError ) => {
        if ( !allowCollapse ) {
            return;
        }
        if ( hasError ) {
            setContainerHeight( 'auto' );
            return;
        }

        const baseEl = document.querySelector( `.${ name }` );
        const baseHeight = 52;
        const checkedElement = baseEl.querySelector( '.ant-radio-wrapper-checked' );
        const height = checkedElement ? checkedElement.offsetHeight : 0;

        if ( !isCollapsed ) {
            setContainerHeight( height > baseHeight ? height + 20 : baseHeight + 20 );
        } else {
            setContainerHeight( 'auto' );
        }
        if ( !isCollapsed ) {
            setTimeout( () => {
                setIsCollapsedItem( !isCollapsedItem );
            }, 270 );
        } else {
            setIsCollapsedItem( !isCollapsedItem );
        }
        setCollapsed( !isCollapsed );

        calculateHeight();
    };

    /**
     * Handles the onChange event of the radio group
     * @param {Event} e - The change event
     */
    const handleOnChange = ( e ) => {
        if ( typeof onChange === 'function' ) {
            onChange( e );
        }
    };

    useEffect( () => {
        if ( options && options.length > 0 ) {
            const mappedOptions = options.map( ( option ) => {
                return (
                    <Card
                        key={ option.value }
                        description={ option.description }
                        label={ option.label }
                        value={ option.value || option.id }
                        isChecked={ option.value === value }
                        disabled={ option.disabled }
                        disabledReason={ option.disabledReason }
                        onClick={ () => handleCollapse( option.hasError ) }
                        onKeyPress={ () => handleCollapse( option.hasError ) }
                        onChange={ handleOnChange }
                        icon={ option.icon }
                        reversed={ reversed }
                        hasError={ option.hasError }
                        showButton={ option.showButton }
                        buttonProps={ option.buttonProps }
                        showCheckIcon={ showCheckIcon }
                        { ...restProps }
                    >
                        { option.label }
                    </Card>
                );
            } );
            mappedOptions.push(
                <AntRadio className={ isCollapsedItem ? 'origami-radio-card-wrapper empty-1' : 'card-hidden origami-radio-card-wrapper' } key={ 1 } checked={ false } value={ false }>
                    <span className={ 'origami-radio-card-text-wrapper' }>
                        <div className='origami-radio-card-label'>
                        </div>
                        <div className='origami-radio-card-description'>
                        </div>
                    </span>
                </AntRadio>,
                <AntRadio className={ isCollapsedItem ? 'origami-radio-card-wrapper empty-2' : 'card-hidden origami-radio-card-wrapper' } key={ 3 } checked={ false } value={ false }>
                    <span className={ 'origami-radio-card-text-wrapper' }>
                        <div className='origami-radio-card-label'>
                        </div>
                        <div className='origami-radio-card-description'>
                        </div>
                    </span>
                </AntRadio>
            );
            setChildrenToRender( mappedOptions );
        }
    }, [ options, isCollapsedItem ] );

    useEffect( () => {
        if ( startCollapsed === true ) {
            handleCollapse( false );
        }
    }, [ startCollapsed ] );

    return (
        <AnimateHeight duration={ 300 } height={ containerHeight } ref={ marginRef } easing='ease-out'>
            <AntRadio.Group
                ref={ containerRef }
                className={ isCollapsedItem ? `origami-radio-group-card program-selector collapsed ${ name }` : `origami-radio-group-card program-selector ${ name }` }
                value={ value }
                defaultValue={ defaultValue }
                onChange={ ( e ) => handleOnChange( e ) }
                { ...restProps }
            >
                { childrenToRender }
            </AntRadio.Group>
        </AnimateHeight>
    );
};

export default CardGroup;