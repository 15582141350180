// THIS FILE IS GENERATED, DO NOT EDIT DIRECTLY
import React from 'react';

// import the default export from each component for usage in the defaultExport array below
import WorkBag from './WorkBag.js';
import VolumeOff from './VolumeOff.js';
import Volume from './Volume.js';
import Users from './Users.js';
import UserAdd from './UserAdd.js';
import User from './User.js';
import Upload from './Upload.js';
import UnreadBadge from './UnreadBadge.js';
import Trophy from './Trophy.js';
import Trees from './Trees.js';
import Trash from './Trash.js';
import Timer from './Timer.js';
import TimeSmall from './TimeSmall.js';
import TimeFilled from './TimeFilled.js';
import Time from './Time.js';
import ThumbUp from './ThumbUp.js';
import ThumbDown from './ThumbDown.js';
import TextFormat from './TextFormat.js';
import TextFont from './TextFont.js';
import TennisBall from './TennisBall.js';
import Template from './Template.js';
import Target from './Target.js';
import Tag from './Tag.js';
import Symbols from './Symbols.js';
import Support from './Support.js';
import Store from './Store.js';
import StarFilled from './StarFilled.js';
import Star from './Star.js';
import Spinner from './Spinner.js';
import ShareArrow from './ShareArrow.js';
import Share from './Share.js';
import Search from './Search.js';
import Rocket from './Rocket.js';
import RibbonMedal from './RibbonMedal.js';
import Replay from './Replay.js';
import Repeat from './Repeat.js';
import ReadCheck from './ReadCheck.js';
import ProfileOff from './ProfileOff.js';
import Profile from './Profile.js';
import Print from './Print.js';
import Play from './Play.js';
import PictureGallery from './PictureGallery.js';
import PictureAdd from './PictureAdd.js';
import Pause from './Pause.js';
import Organization from './Organization.js';
import Options from './Options.js';
import Notes from './Notes.js';
import MoreDotsVert from './MoreDotsVert.js';
import MoreDotsHorz from './MoreDotsHorz.js';
import Merchandise from './Merchandise.js';
import Menu from './Menu.js';
import MathPlusLarge from './MathPlusLarge.js';
import MathPlus from './MathPlus.js';
import MathMinusLarge from './MathMinusLarge.js';
import MathMinus from './MathMinus.js';
import Mastery from './Mastery.js';
import LogOut from './LogOut.js';
import LogIn from './LogIn.js';
import LockUnlock from './LockUnlock.js';
import Lock from './Lock.js';
import Location from './Location.js';
import ListAdd from './ListAdd.js';
import List from './List.js';
import LinkExternalLarge from './LinkExternalLarge.js';
import LinkExternal from './LinkExternal.js';
import Link from './Link.js';
import Lightbulb from './Lightbulb.js';
import InfoFilled from './InfoFilled.js';
import Info from './Info.js';
import Hourglass from './Hourglass.js';
import Home from './Home.js';
import History from './History.js';
import HighFive from './HighFive.js';
import HelpFilled from './HelpFilled.js';
import Help from './Help.js';
import Grid from './Grid.js';
import Globe from './Globe.js';
import Gift from './Gift.js';
import Gear from './Gear.js';
import Games from './Games.js';
import FoodAndDrink from './FoodAndDrink.js';
import Flag from './Flag.js';
import FilterRefine from './FilterRefine.js';
import FilterReduce from './FilterReduce.js';
import FileVideo from './FileVideo.js';
import FilePdf from './FilePdf.js';
import FileImage from './FileImage.js';
import EyeClosed from './EyeClosed.js';
import Eye from './Eye.js';
import Experiences from './Experiences.js';
import ErrorFilled from './ErrorFilled.js';
import Error from './Error.js';
import EnhanceAI from './EnhanceAI.js';
import EmojiSmile from './EmojiSmile.js';
import EmojiAddReaction from './EmojiAddReaction.js';
import Email from './Email.js';
import EditSmall from './EditSmall.js';
import Edit from './Edit.js';
import Download from './Download.js';
import Dollar from './Dollar.js';
import DangerFilled from './DangerFilled.js';
import Danger from './Danger.js';
import CreditCard from './CreditCard.js';
import Controller from './Controller.js';
import Compose from './Compose.js';
import CommentHeart from './CommentHeart.js';
import CommentBars from './CommentBars.js';
import ClosedCaptionFilled from './ClosedCaptionFilled.js';
import ClosedCaption from './ClosedCaption.js';
import CloseLarge from './CloseLarge.js';
import Close from './Close.js';
import CircleClosedFilled from './CircleClosedFilled.js';
import CircleClosed from './CircleClosed.js';
import CircleCloseFilled from './CircleCloseFilled.js';
import CircleClose from './CircleClose.js';
import CircleCheckSmallFilled from './CircleCheckSmallFilled.js';
import CircleCheckSmall from './CircleCheckSmall.js';
import CircleCheckFilled from './CircleCheckFilled.js';
import CircleCheck from './CircleCheck.js';
import Circle from './Circle.js';
import ChevronUp from './ChevronUp.js';
import ChevronRight from './ChevronRight.js';
import ChevronLeft from './ChevronLeft.js';
import ChevronDown from './ChevronDown.js';
import ChevronDoubleUp from './ChevronDoubleUp.js';
import ChevronDoubleRight from './ChevronDoubleRight.js';
import ChevronDoubleLeft from './ChevronDoubleLeft.js';
import ChevronDoubleDown from './ChevronDoubleDown.js';
import Check from './Check.js';
import ChartPie from './ChartPie.js';
import ChartBarVert from './ChartBarVert.js';
import ChartBarHorz from './ChartBarHorz.js';
import CaretUpSmall from './CaretUpSmall.js';
import CaretUp from './CaretUp.js';
import CaretSortUp from './CaretSortUp.js';
import CaretSortDown from './CaretSortDown.js';
import CaretSort from './CaretSort.js';
import CaretRightSmall from './CaretRightSmall.js';
import CaretRight from './CaretRight.js';
import CaretLeftSmall from './CaretLeftSmall.js';
import CaretLeft from './CaretLeft.js';
import CaretDownSmall from './CaretDownSmall.js';
import CaretDown from './CaretDown.js';
import Camera from './Camera.js';
import CalendarWeek from './CalendarWeek.js';
import CalendarDay from './CalendarDay.js';
import Cake from './Cake.js';
import Building from './Building.js';
import Bookmark from './Bookmark.js';
import BellOff from './BellOff.js';
import Bell from './Bell.js';
import Badge from './Badge.js';
import Attachment from './Attachment.js';
import ArrowUp from './ArrowUp.js';
import ArrowRight from './ArrowRight.js';
import ArrowLeft from './ArrowLeft.js';
import ArrowExpandRight from './ArrowExpandRight.js';
import ArrowExchange from './ArrowExchange.js';
import ArrowDown from './ArrowDown.js';
import ArrowCompressRight from './ArrowCompressRight.js';
import Airplane from './Airplane.js';

// export the default export from each component for individual icon usage
export { default as WorkBag } from './WorkBag.js';
export { default as VolumeOff } from './VolumeOff.js';
export { default as Volume } from './Volume.js';
export { default as Users } from './Users.js';
export { default as UserAdd } from './UserAdd.js';
export { default as User } from './User.js';
export { default as Upload } from './Upload.js';
export { default as UnreadBadge } from './UnreadBadge.js';
export { default as Trophy } from './Trophy.js';
export { default as Trees } from './Trees.js';
export { default as Trash } from './Trash.js';
export { default as Timer } from './Timer.js';
export { default as TimeSmall } from './TimeSmall.js';
export { default as TimeFilled } from './TimeFilled.js';
export { default as Time } from './Time.js';
export { default as ThumbUp } from './ThumbUp.js';
export { default as ThumbDown } from './ThumbDown.js';
export { default as TextFormat } from './TextFormat.js';
export { default as TextFont } from './TextFont.js';
export { default as TennisBall } from './TennisBall.js';
export { default as Template } from './Template.js';
export { default as Target } from './Target.js';
export { default as Tag } from './Tag.js';
export { default as Symbols } from './Symbols.js';
export { default as Support } from './Support.js';
export { default as Store } from './Store.js';
export { default as StarFilled } from './StarFilled.js';
export { default as Star } from './Star.js';
export { default as Spinner } from './Spinner.js';
export { default as ShareArrow } from './ShareArrow.js';
export { default as Share } from './Share.js';
export { default as Search } from './Search.js';
export { default as Rocket } from './Rocket.js';
export { default as RibbonMedal } from './RibbonMedal.js';
export { default as Replay } from './Replay.js';
export { default as Repeat } from './Repeat.js';
export { default as ReadCheck } from './ReadCheck.js';
export { default as ProfileOff } from './ProfileOff.js';
export { default as Profile } from './Profile.js';
export { default as Print } from './Print.js';
export { default as Play } from './Play.js';
export { default as PictureGallery } from './PictureGallery.js';
export { default as PictureAdd } from './PictureAdd.js';
export { default as Pause } from './Pause.js';
export { default as Organization } from './Organization.js';
export { default as Options } from './Options.js';
export { default as Notes } from './Notes.js';
export { default as MoreDotsVert } from './MoreDotsVert.js';
export { default as MoreDotsHorz } from './MoreDotsHorz.js';
export { default as Merchandise } from './Merchandise.js';
export { default as Menu } from './Menu.js';
export { default as MathPlusLarge } from './MathPlusLarge.js';
export { default as MathPlus } from './MathPlus.js';
export { default as MathMinusLarge } from './MathMinusLarge.js';
export { default as MathMinus } from './MathMinus.js';
export { default as Mastery } from './Mastery.js';
export { default as LogOut } from './LogOut.js';
export { default as LogIn } from './LogIn.js';
export { default as LockUnlock } from './LockUnlock.js';
export { default as Lock } from './Lock.js';
export { default as Location } from './Location.js';
export { default as ListAdd } from './ListAdd.js';
export { default as List } from './List.js';
export { default as LinkExternalLarge } from './LinkExternalLarge.js';
export { default as LinkExternal } from './LinkExternal.js';
export { default as Link } from './Link.js';
export { default as Lightbulb } from './Lightbulb.js';
export { default as InfoFilled } from './InfoFilled.js';
export { default as Info } from './Info.js';
export { default as Hourglass } from './Hourglass.js';
export { default as Home } from './Home.js';
export { default as History } from './History.js';
export { default as HighFive } from './HighFive.js';
export { default as HelpFilled } from './HelpFilled.js';
export { default as Help } from './Help.js';
export { default as Grid } from './Grid.js';
export { default as Globe } from './Globe.js';
export { default as Gift } from './Gift.js';
export { default as Gear } from './Gear.js';
export { default as Games } from './Games.js';
export { default as FoodAndDrink } from './FoodAndDrink.js';
export { default as Flag } from './Flag.js';
export { default as FilterRefine } from './FilterRefine.js';
export { default as FilterReduce } from './FilterReduce.js';
export { default as FileVideo } from './FileVideo.js';
export { default as FilePdf } from './FilePdf.js';
export { default as FileImage } from './FileImage.js';
export { default as EyeClosed } from './EyeClosed.js';
export { default as Eye } from './Eye.js';
export { default as Experiences } from './Experiences.js';
export { default as ErrorFilled } from './ErrorFilled.js';
export { default as Error } from './Error.js';
export { default as EnhanceAI } from './EnhanceAI.js';
export { default as EmojiSmile } from './EmojiSmile.js';
export { default as EmojiAddReaction } from './EmojiAddReaction.js';
export { default as Email } from './Email.js';
export { default as EditSmall } from './EditSmall.js';
export { default as Edit } from './Edit.js';
export { default as Download } from './Download.js';
export { default as Dollar } from './Dollar.js';
export { default as DangerFilled } from './DangerFilled.js';
export { default as Danger } from './Danger.js';
export { default as CreditCard } from './CreditCard.js';
export { default as Controller } from './Controller.js';
export { default as Compose } from './Compose.js';
export { default as CommentHeart } from './CommentHeart.js';
export { default as CommentBars } from './CommentBars.js';
export { default as ClosedCaptionFilled } from './ClosedCaptionFilled.js';
export { default as ClosedCaption } from './ClosedCaption.js';
export { default as CloseLarge } from './CloseLarge.js';
export { default as Close } from './Close.js';
export { default as CircleClosedFilled } from './CircleClosedFilled.js';
export { default as CircleClosed } from './CircleClosed.js';
export { default as CircleCloseFilled } from './CircleCloseFilled.js';
export { default as CircleClose } from './CircleClose.js';
export { default as CircleCheckSmallFilled } from './CircleCheckSmallFilled.js';
export { default as CircleCheckSmall } from './CircleCheckSmall.js';
export { default as CircleCheckFilled } from './CircleCheckFilled.js';
export { default as CircleCheck } from './CircleCheck.js';
export { default as Circle } from './Circle.js';
export { default as ChevronUp } from './ChevronUp.js';
export { default as ChevronRight } from './ChevronRight.js';
export { default as ChevronLeft } from './ChevronLeft.js';
export { default as ChevronDown } from './ChevronDown.js';
export { default as ChevronDoubleUp } from './ChevronDoubleUp.js';
export { default as ChevronDoubleRight } from './ChevronDoubleRight.js';
export { default as ChevronDoubleLeft } from './ChevronDoubleLeft.js';
export { default as ChevronDoubleDown } from './ChevronDoubleDown.js';
export { default as Check } from './Check.js';
export { default as ChartPie } from './ChartPie.js';
export { default as ChartBarVert } from './ChartBarVert.js';
export { default as ChartBarHorz } from './ChartBarHorz.js';
export { default as CaretUpSmall } from './CaretUpSmall.js';
export { default as CaretUp } from './CaretUp.js';
export { default as CaretSortUp } from './CaretSortUp.js';
export { default as CaretSortDown } from './CaretSortDown.js';
export { default as CaretSort } from './CaretSort.js';
export { default as CaretRightSmall } from './CaretRightSmall.js';
export { default as CaretRight } from './CaretRight.js';
export { default as CaretLeftSmall } from './CaretLeftSmall.js';
export { default as CaretLeft } from './CaretLeft.js';
export { default as CaretDownSmall } from './CaretDownSmall.js';
export { default as CaretDown } from './CaretDown.js';
export { default as Camera } from './Camera.js';
export { default as CalendarWeek } from './CalendarWeek.js';
export { default as CalendarDay } from './CalendarDay.js';
export { default as Cake } from './Cake.js';
export { default as Building } from './Building.js';
export { default as Bookmark } from './Bookmark.js';
export { default as BellOff } from './BellOff.js';
export { default as Bell } from './Bell.js';
export { default as Badge } from './Badge.js';
export { default as Attachment } from './Attachment.js';
export { default as ArrowUp } from './ArrowUp.js';
export { default as ArrowRight } from './ArrowRight.js';
export { default as ArrowLeft } from './ArrowLeft.js';
export { default as ArrowExpandRight } from './ArrowExpandRight.js';
export { default as ArrowExchange } from './ArrowExchange.js';
export { default as ArrowDown } from './ArrowDown.js';
export { default as ArrowCompressRight } from './ArrowCompressRight.js';
export { default as Airplane } from './Airplane.js';

// export an array of those imports
export default [
    {
        name: 'WorkBag',
        component: <WorkBag />
    },
    {
        name: 'VolumeOff',
        component: <VolumeOff />
    },
    {
        name: 'Volume',
        component: <Volume />
    },
    {
        name: 'Users',
        component: <Users />
    },
    {
        name: 'UserAdd',
        component: <UserAdd />
    },
    {
        name: 'User',
        component: <User />
    },
    {
        name: 'Upload',
        component: <Upload />
    },
    {
        name: 'UnreadBadge',
        component: <UnreadBadge />
    },
    {
        name: 'Trophy',
        component: <Trophy />
    },
    {
        name: 'Trees',
        component: <Trees />
    },
    {
        name: 'Trash',
        component: <Trash />
    },
    {
        name: 'Timer',
        component: <Timer />
    },
    {
        name: 'TimeSmall',
        component: <TimeSmall />
    },
    {
        name: 'TimeFilled',
        component: <TimeFilled />
    },
    {
        name: 'Time',
        component: <Time />
    },
    {
        name: 'ThumbUp',
        component: <ThumbUp />
    },
    {
        name: 'ThumbDown',
        component: <ThumbDown />
    },
    {
        name: 'TextFormat',
        component: <TextFormat />
    },
    {
        name: 'TextFont',
        component: <TextFont />
    },
    {
        name: 'TennisBall',
        component: <TennisBall />
    },
    {
        name: 'Template',
        component: <Template />
    },
    {
        name: 'Target',
        component: <Target />
    },
    {
        name: 'Tag',
        component: <Tag />
    },
    {
        name: 'Symbols',
        component: <Symbols />
    },
    {
        name: 'Support',
        component: <Support />
    },
    {
        name: 'Store',
        component: <Store />
    },
    {
        name: 'StarFilled',
        component: <StarFilled />
    },
    {
        name: 'Star',
        component: <Star />
    },
    {
        name: 'Spinner',
        component: <Spinner />
    },
    {
        name: 'ShareArrow',
        component: <ShareArrow />
    },
    {
        name: 'Share',
        component: <Share />
    },
    {
        name: 'Search',
        component: <Search />
    },
    {
        name: 'Rocket',
        component: <Rocket />
    },
    {
        name: 'RibbonMedal',
        component: <RibbonMedal />
    },
    {
        name: 'Replay',
        component: <Replay />
    },
    {
        name: 'Repeat',
        component: <Repeat />
    },
    {
        name: 'ReadCheck',
        component: <ReadCheck />
    },
    {
        name: 'ProfileOff',
        component: <ProfileOff />
    },
    {
        name: 'Profile',
        component: <Profile />
    },
    {
        name: 'Print',
        component: <Print />
    },
    {
        name: 'Play',
        component: <Play />
    },
    {
        name: 'PictureGallery',
        component: <PictureGallery />
    },
    {
        name: 'PictureAdd',
        component: <PictureAdd />
    },
    {
        name: 'Pause',
        component: <Pause />
    },
    {
        name: 'Organization',
        component: <Organization />
    },
    {
        name: 'Options',
        component: <Options />
    },
    {
        name: 'Notes',
        component: <Notes />
    },
    {
        name: 'MoreDotsVert',
        component: <MoreDotsVert />
    },
    {
        name: 'MoreDotsHorz',
        component: <MoreDotsHorz />
    },
    {
        name: 'Merchandise',
        component: <Merchandise />
    },
    {
        name: 'Menu',
        component: <Menu />
    },
    {
        name: 'MathPlusLarge',
        component: <MathPlusLarge />
    },
    {
        name: 'MathPlus',
        component: <MathPlus />
    },
    {
        name: 'MathMinusLarge',
        component: <MathMinusLarge />
    },
    {
        name: 'MathMinus',
        component: <MathMinus />
    },
    {
        name: 'Mastery',
        component: <Mastery />
    },
    {
        name: 'LogOut',
        component: <LogOut />
    },
    {
        name: 'LogIn',
        component: <LogIn />
    },
    {
        name: 'LockUnlock',
        component: <LockUnlock />
    },
    {
        name: 'Lock',
        component: <Lock />
    },
    {
        name: 'Location',
        component: <Location />
    },
    {
        name: 'ListAdd',
        component: <ListAdd />
    },
    {
        name: 'List',
        component: <List />
    },
    {
        name: 'LinkExternalLarge',
        component: <LinkExternalLarge />
    },
    {
        name: 'LinkExternal',
        component: <LinkExternal />
    },
    {
        name: 'Link',
        component: <Link />
    },
    {
        name: 'Lightbulb',
        component: <Lightbulb />
    },
    {
        name: 'InfoFilled',
        component: <InfoFilled />
    },
    {
        name: 'Info',
        component: <Info />
    },
    {
        name: 'Hourglass',
        component: <Hourglass />
    },
    {
        name: 'Home',
        component: <Home />
    },
    {
        name: 'History',
        component: <History />
    },
    {
        name: 'HighFive',
        component: <HighFive />
    },
    {
        name: 'HelpFilled',
        component: <HelpFilled />
    },
    {
        name: 'Help',
        component: <Help />
    },
    {
        name: 'Grid',
        component: <Grid />
    },
    {
        name: 'Globe',
        component: <Globe />
    },
    {
        name: 'Gift',
        component: <Gift />
    },
    {
        name: 'Gear',
        component: <Gear />
    },
    {
        name: 'Games',
        component: <Games />
    },
    {
        name: 'FoodAndDrink',
        component: <FoodAndDrink />
    },
    {
        name: 'Flag',
        component: <Flag />
    },
    {
        name: 'FilterRefine',
        component: <FilterRefine />
    },
    {
        name: 'FilterReduce',
        component: <FilterReduce />
    },
    {
        name: 'FileVideo',
        component: <FileVideo />
    },
    {
        name: 'FilePdf',
        component: <FilePdf />
    },
    {
        name: 'FileImage',
        component: <FileImage />
    },
    {
        name: 'EyeClosed',
        component: <EyeClosed />
    },
    {
        name: 'Eye',
        component: <Eye />
    },
    {
        name: 'Experiences',
        component: <Experiences />
    },
    {
        name: 'ErrorFilled',
        component: <ErrorFilled />
    },
    {
        name: 'Error',
        component: <Error />
    },
    {
        name: 'EnhanceAI',
        component: <EnhanceAI />
    },
    {
        name: 'EmojiSmile',
        component: <EmojiSmile />
    },
    {
        name: 'EmojiAddReaction',
        component: <EmojiAddReaction />
    },
    {
        name: 'Email',
        component: <Email />
    },
    {
        name: 'EditSmall',
        component: <EditSmall />
    },
    {
        name: 'Edit',
        component: <Edit />
    },
    {
        name: 'Download',
        component: <Download />
    },
    {
        name: 'Dollar',
        component: <Dollar />
    },
    {
        name: 'DangerFilled',
        component: <DangerFilled />
    },
    {
        name: 'Danger',
        component: <Danger />
    },
    {
        name: 'CreditCard',
        component: <CreditCard />
    },
    {
        name: 'Controller',
        component: <Controller />
    },
    {
        name: 'Compose',
        component: <Compose />
    },
    {
        name: 'CommentHeart',
        component: <CommentHeart />
    },
    {
        name: 'CommentBars',
        component: <CommentBars />
    },
    {
        name: 'ClosedCaptionFilled',
        component: <ClosedCaptionFilled />
    },
    {
        name: 'ClosedCaption',
        component: <ClosedCaption />
    },
    {
        name: 'CloseLarge',
        component: <CloseLarge />
    },
    {
        name: 'Close',
        component: <Close />
    },
    {
        name: 'CircleClosedFilled',
        component: <CircleClosedFilled />
    },
    {
        name: 'CircleClosed',
        component: <CircleClosed />
    },
    {
        name: 'CircleCloseFilled',
        component: <CircleCloseFilled />
    },
    {
        name: 'CircleClose',
        component: <CircleClose />
    },
    {
        name: 'CircleCheckSmallFilled',
        component: <CircleCheckSmallFilled />
    },
    {
        name: 'CircleCheckSmall',
        component: <CircleCheckSmall />
    },
    {
        name: 'CircleCheckFilled',
        component: <CircleCheckFilled />
    },
    {
        name: 'CircleCheck',
        component: <CircleCheck />
    },
    {
        name: 'Circle',
        component: <Circle />
    },
    {
        name: 'ChevronUp',
        component: <ChevronUp />
    },
    {
        name: 'ChevronRight',
        component: <ChevronRight />
    },
    {
        name: 'ChevronLeft',
        component: <ChevronLeft />
    },
    {
        name: 'ChevronDown',
        component: <ChevronDown />
    },
    {
        name: 'ChevronDoubleUp',
        component: <ChevronDoubleUp />
    },
    {
        name: 'ChevronDoubleRight',
        component: <ChevronDoubleRight />
    },
    {
        name: 'ChevronDoubleLeft',
        component: <ChevronDoubleLeft />
    },
    {
        name: 'ChevronDoubleDown',
        component: <ChevronDoubleDown />
    },
    {
        name: 'Check',
        component: <Check />
    },
    {
        name: 'ChartPie',
        component: <ChartPie />
    },
    {
        name: 'ChartBarVert',
        component: <ChartBarVert />
    },
    {
        name: 'ChartBarHorz',
        component: <ChartBarHorz />
    },
    {
        name: 'CaretUpSmall',
        component: <CaretUpSmall />
    },
    {
        name: 'CaretUp',
        component: <CaretUp />
    },
    {
        name: 'CaretSortUp',
        component: <CaretSortUp />
    },
    {
        name: 'CaretSortDown',
        component: <CaretSortDown />
    },
    {
        name: 'CaretSort',
        component: <CaretSort />
    },
    {
        name: 'CaretRightSmall',
        component: <CaretRightSmall />
    },
    {
        name: 'CaretRight',
        component: <CaretRight />
    },
    {
        name: 'CaretLeftSmall',
        component: <CaretLeftSmall />
    },
    {
        name: 'CaretLeft',
        component: <CaretLeft />
    },
    {
        name: 'CaretDownSmall',
        component: <CaretDownSmall />
    },
    {
        name: 'CaretDown',
        component: <CaretDown />
    },
    {
        name: 'Camera',
        component: <Camera />
    },
    {
        name: 'CalendarWeek',
        component: <CalendarWeek />
    },
    {
        name: 'CalendarDay',
        component: <CalendarDay />
    },
    {
        name: 'Cake',
        component: <Cake />
    },
    {
        name: 'Building',
        component: <Building />
    },
    {
        name: 'Bookmark',
        component: <Bookmark />
    },
    {
        name: 'BellOff',
        component: <BellOff />
    },
    {
        name: 'Bell',
        component: <Bell />
    },
    {
        name: 'Badge',
        component: <Badge />
    },
    {
        name: 'Attachment',
        component: <Attachment />
    },
    {
        name: 'ArrowUp',
        component: <ArrowUp />
    },
    {
        name: 'ArrowRight',
        component: <ArrowRight />
    },
    {
        name: 'ArrowLeft',
        component: <ArrowLeft />
    },
    {
        name: 'ArrowExpandRight',
        component: <ArrowExpandRight />
    },
    {
        name: 'ArrowExchange',
        component: <ArrowExchange />
    },
    {
        name: 'ArrowDown',
        component: <ArrowDown />
    },
    {
        name: 'ArrowCompressRight',
        component: <ArrowCompressRight />
    },
    {
        name: 'Airplane',
        component: <Airplane />
    }
];