// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  height: 20rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}
.hero-background-fadein {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center;
  opacity: 0;
  animation-name: hero-background-fadein-animation;
  animation-duration: 500 ms;
}
@keyframes hero-background-fadein-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.hero-content {
  position: relative;
  width: 960px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.nkl-card-bordered {
  border: #000 solid 0px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
