import React, { forwardRef } from 'react';
import { Radio as AntRadio } from 'antd';
import Card from './card';
import Button from './button';

const RadioGroup = forwardRef( ( { children, options, optionType, showLockIcon, value, ...restProps }, ref ) => {
    let childrenToRender = children;
    if ( options && options.length > 0 ) {
        childrenToRender = options.map( ( option ) => {
            if ( typeof option === 'string' || typeof option === 'number' ) {
                if ( optionType === 'card' ) {
                    return (
                        <Card key={ option } value={ option } { ...restProps }>
                            { option }
                        </Card>
                    );
                } else if ( optionType === 'button' ) {
                    return (
                        <Button key={ option } value={ option } { ...restProps }>
                            { option }
                        </Button>
                    );
                }
            }
            if ( optionType === 'card' ) {
                return (
                    <Card
                        key={ option.value }
                        description={ option.description }
                        showLockIcon={ showLockIcon || option.showLockIcon }
                        value={ option.value }
                        disabled={ option.disabled }
                        { ...restProps }
                    >
                        { option.label }
                    </Card>
                );
            } else if ( optionType === 'button' ) {
                return (
                    <Button
                        key={ option.value }
                        description={ option.description }
                        showLockIcon={ showLockIcon || option.showLockIcon }
                        value={ option.value }
                        disabled={ option.disabled }
                        { ...restProps }
                    >
                        { option.label }
                    </Button>
                );
            }
        } );
    }

    return (
        <AntRadio.Group
            ref={ ref }
            className={ optionType ? `origami-radio-group-${ optionType }` : 'origami-radio-group' }
            options={ optionType === 'card' || optionType === 'button' ? undefined : options }
            optionType={ optionType === 'card' || optionType === 'button' ? undefined : optionType }
            value={ value }
            { ...restProps }
        >
            { childrenToRender }
        </AntRadio.Group>
    );
} );

export default RadioGroup;