import React, { forwardRef } from 'react';
import { Radio as AntRadio, Checkbox as AntCheckBox } from 'antd';
import { Button } from '../../index.js';
import classNames from 'classnames';
import { CircleCheckFilled, Circle, CircleCloseFilled } from '@nackle/origami-icons';
import { useState } from 'react';

/**
 * RadioCard component that renders either a radio button or checkbox with additional styling and features.
 *
 * @component
 * @param {Object} props - The properties that define the RadioCard component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the RadioCard.
 * @param {boolean} [props.isChecked] - Determines if the RadioCard is initially checked.
 * @param {string} [props.description] - Additional description text for the RadioCard.
 * @param {boolean} [props.disabled] - If true, the RadioCard will be disabled.
 * @param {string} [props.label] - The label text for the RadioCard.
 * @param {React.ReactNode} [props.icon] - An icon to be displayed in the RadioCard.
 * @param {boolean} [props.showCheckbox] - If true, renders as a checkbox instead of a radio button.
 * @param {boolean} [props.reversed] - If true, reverses the layout of the RadioCard.
 * @param {string} [props.className] - Additional CSS class names to be applied to the RadioCard.
 * @param {boolean} [props.hasError] - If true, displays an error state.
 * @param {boolean} [props.showButton] - If true, displays a button in the RadioCard.
 * @param {Object} [props.buttonProps] - Properties to be passed to the Button component.
 * @param {Object} [props.checkboxProps] - Properties to be passed to the Checkbox component when showCheckbox is true.
 * @param {boolean} [props.showCheckIcon] - If true, displays a check icon.
 * @param {Function} [props.onChange] - Callback function triggered when the RadioCard's state changes.
 * @param {boolean} [props.deselectOthers] - If true, allows deselection of other RadioCards (not implemented in the current code).
 * @param {boolean} [props.deselect] - If true, allows deselection of the RadioCard (not implemented in the current code).
 * @param {string} [props.disabledReason] - Text explaining why the RadioCard is disabled.
 * @param {Object} restProps - Additional props to be spread on the underlying radio or checkbox component.
 * @param {React.Ref} ref - Forwarded ref to be attached to the component.
 *
 * @returns {React.ReactElement} A styled radio button or checkbox component with additional features.
 *
 * @example
 * <RadioCard
 *   label="Option 1"
 *   description="This is a description"
 *   icon={<SomeIcon />}
 *   onChange={handleChange}
 *   disabled={false}
 * />
 */
const RadioCard = forwardRef( ( props, ref ) => {
    const {
        children,
        isChecked,
        description,
        disabled,
        label,
        icon,
        showCheckbox,
        reversed,
        className,
        hasError,
        showButton,
        buttonProps,
        checkboxProps,
        showCheckIcon,
        onChange,
        deselectOthers,
        deselect,
        disabledReason,
        ...restProps
    } = props;
    const [ checked, setIsChecked ] = useState( isChecked );
    const radioClasses = classNames( {
        'origami-radio-card-wrapper': true,
        'is-checkbox-variant': showCheckbox ? true : false,
        'checkbox-variant': showCheckbox,
        [ className ]: className ? true : false,
        'reversed': reversed,
        'hasError': hasError ? true : false,
        'full-width': true,
        'no-description': description ? false : true,
        'has-button': showButton ? true : false,
        'show-check-icon': showCheckIcon ? true : false
    } );
    const innerClasses = classNames( {
        'origami-radio-card-info-wrapper': true,
        'has-button': showButton ? true : false,
        'has-icon': icon && !showButton ? true : false,
        'has-reason': disabledReason && !reversed ? true : false
    } );
    const handleOnChange = ( e ) => {
        setIsChecked( true );
        if ( typeof onChange === 'function' ) {
            onChange( e );
        }
    };
    const childrenToRender = (
        <div className="radio-card-container">
            {
                hasError && showCheckIcon &&
                <CircleCloseFilled className="error-circle" />

            }
            {
                !hasError &&
                <>
                    <Circle className={ checked ? 'circle is-checked' : 'circle' } />
                    <CircleCheckFilled className={ checked ? 'circle-check is-checked' : 'circle-check' } />
                </>
            }
            <div className={ innerClasses }>
                <span className='origami-radio-card-text-wrapper'>
                    <div className='origami-radio-card-label'>
                        { label ? label : children }
                    </div>
                    { description ? (
                        <div className='origami-radio-card-description'>
                            { description }
                        </div>
                    ) : null }
                </span>
                { ( icon && !showButton && !disabled ) ? (
                    <span className='origami-radio-icon'>
                        { icon }
                    </span>
                ) : showButton && !reversed && !disabled ? (
                    <span className='origami-radio-card-button'>
                        <Button type="link" disabled={ disabled } { ...buttonProps } size={ 'small' }>{ buttonProps.label }</Button>
                    </span>
                ) : null }
                {
                    disabled && !reversed &&
                    <div className="disabled-reason">{ disabledReason } </div>
                }
            </div>
        </div>
    );

    return (
        showCheckbox ?
            <AntCheckBox className={ radioClasses } disabled={ disabled } { ...checkboxProps } >
                { childrenToRender }
            </AntCheckBox>
            :
            <AntRadio ref={ ref } className={ radioClasses } disabled={ disabled } onChange={ handleOnChange } { ...restProps }>
                { childrenToRender }
            </AntRadio>
    );
}
);
export default RadioCard;